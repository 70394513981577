/* Message.css */

.container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.fixed-container {
    position: relative;
    height: 450px;
    /* Adjust height as needed */
    overflow-y: auto;
    /* Change to auto to enable scrolling */
    margin-bottom: 20px;
}

.message-container {
    padding-right: 20px;
    /* Add padding to ensure scrollbar doesn't overlay content */
}

.message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.user-message {
    justify-content: flex-end;
}

.admin-message {
    justify-content: flex-start;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
}

.message-content {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    background-color: #ECEFF1;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
}

.message-content-admin {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    background-color: #abb4e8;
    padding: 10px;
    border-radius: 10px;
    /* margin-left: 10px; */
    color: black;
}

.info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sent-time {
    font-size: 12px;
    color: black;
}

.status {
    font-size: 12px;
    color: #777;
}

.typing {
    font-style: italic;
    color: #777;
    margin-left: 50px;
}

.input {
   margin-bottom: 0;
}

.input:focus {
    outline: none;
    border-color: indigo;
    /* Change border color on focus */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .message-content {
        max-width: 70%;
    }
}

@media screen and (max-width: 576px) {
    .message-content {
        max-width: 60%;
    }

    .typing {
        margin-left: 20px;
    }
}